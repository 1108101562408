<template>
  <div>
    <div class="">
      <b-container class="selectRD">
        <b-row class="ReportBkwhite">
          <b-col
            class="noPadLogo_left"
          >
            <b-img class="tourLogoS" :src="data.event_sponsor_logo"></b-img>
            <div class="tournInfo">
              <h2 class="tournTitle" :class="{'adtColour' : develop == 'adt'}">{{data.full_name}}</h2>
              <p class="tournDates">{{data.course_dates}}</p>
            </div>
          </b-col>
          <b-col
            v-if="develop !== 'adt'"
            class="mobHide iframepadnoright"
          >
            <iframe
              class="iframemovement"
              width="260px"
              height="104px"
              style="border: none!important;"
              src="https://wp-asiantour.ocs-sport.com/wp-content/themes/asian-tour/ball_counter/index.html"
            >
            </iframe>
          </b-col>
        </b-row>
        <b-row>
          <span class="reportsPad">
            <b-col
              class="dropSel"
            >
              <b-row class="reportDropdown">
                <b-col>
                  <div :class="drop">
                    <b-nav card-header pills>
                      <!-- <b-dropdown
                        v-if="Array.isArray(tmparams.reports.reports_entry)"
                        :text="title"
                        class="dropdownReport"
                      >
                        <b-dropdown-item
                          v-on:click="changeReport"
                          v-for="(reports, index) in filterExemptions"
                          :key="index"
                          v-show="
                            reports.report_title !== 'Live Scoring Monitor'
                          "
                          :title="reports.report_url"
                          :value="reports.report_title"
                          >{{ reports.report_title }}</b-dropdown-item
                        >
                      </b-dropdown> -->
                      <b-form-select v-model="reportMenu" class="schedule-dropdown" v-on:change="changeReport()" v-if="Array.isArray(tmparams.reports.reports_entry)">
                        <b-form-select-option 
                          class="opt-arrow"
                          v-for="(value,
                          index) in filterExemptions"
                          :key="index"
                          :value="value.report_title"
                          v-show="
                              value.report_title !== 'Live Scoring Monitor'
                          "
                        >{{value.report_title}}</b-form-select-option>
                      </b-form-select>
                      <b-form-select class="schedule-dropdown" v-on:change="changeReport()" v-else>
                        <b-form-select-option 
                        >Tournament Entries</b-form-select-option>
                      </b-form-select>
                      <!-- <b-dropdown class="dropdownReport" v-else :text="title">
                        <b-dropdown-item
                          v-on:click="changeReport"
                          v-for="(reports, index) in tmparams.reports"
                          :key="index"
                          :title="reports.report_url"
                          :value="reports.report_title"
                          >{{ reports.report_title }}</b-dropdown-item
                        >
                      </b-dropdown> -->
                    </b-nav>
                  </div>
                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col
                  v-if="tmparams.multi_course === 'Y'"
                >
                  <span>Courses:</span>

                  <span
                    class="multiList"
                    v-for="(multi, index) in tmparams.courses.courses_entry"
                    :key="index"
                  >
                    <span :class="multi.course_X_colour">•</span
                    ><span class="multiName">{{ multi.course_X_id }}</span>
                  </span>
                </b-col>
              </b-row> -->
            </b-col>
          </span>
        </b-row>
      </b-container>
    </div>
    <div class="container" v-cloak v-if="loading === true">
      <div class="TWO">
        <div class="reports">
          <div
            class="Three"
            v-if="
              this.report_url.indexOf('tmentry') !== -1 ||
                this.report_url.indexOf('tmdraw') !== -1 ||
                this.report_url.indexOf('tmresult') !== -1 ||
                this.report_url.indexOf('tmoomt') !== -1 ||
                (this.report_url.indexOf('tmscore') !== -1 &&
                  data.match_play === 'Y')
            "
          >
            <ReportTable
              :data="data"
              :previousData="previousData"
              :home="home"
              :title="reportMenu"
              :develop="develop"
              :season="tmparams.tm_params.season_code"
              :code="tmparams.code"
              :config="config"
              :match="tmparams.match_play"
              :team="teamEvent"
              :showGender="showGender"
              :Stableford="Stableford"
              :represents_ind="represents_ind"
              :represents_heading="represents_heading"
              :represents_flag_type="represents_flag_type"
              :showFav="tmparams.reg_allow_reg"
              :is_qualifier="is_qualifier"
              :show_info_web="show_info_web"
              :hide_score_card="hide_score_card"
            />
          </div>
          <div
            v-else-if="this.report_url.indexOf('tmscore') !== -1"
            class="Three"
          >
            <b-tabs
              content-class="ONE"
              :class="{'adt' : develop == 'adt'}"
            >
              <b-tab :title="reportMenu" active >
                <ReportTable
                  :data="data"
                  :scores="data.scores.scores_entry"
                  :previousData="previousData"
                  :home="home"
                  :title="reportMenu"
                  :develop="develop"
                  :season="tmparams.tm_params.season_code"
                  :code="tmparams.code"
                  :config="config"
                  team="N"
                  :match="tmparams.match_play"
                  :showGender="showGender"
                  :Stableford="Stableford"
                  :represents_ind="represents_ind"
                  :represents_heading="represents_heading"
                  :represents_flag_type="represents_flag_type"
                  :showFav="tmparams.reg_allow_reg"
                  :is_qualifier="is_qualifier"
                  :show_info_web="show_info_web"
                  :hide_score_card="hide_score_card"
                />
              </b-tab>
              <b-tab title="Team" v-if="teamEvent === 'Y'">
                <ReportTable
                  :data="data"
                  :scores="data.scores.scores_entry"
                  :previousData="previousData"
                  :home="home"
                  :title="reportMenu"
                  :develop="develop"
                  :season="tmparams.tm_params.season_code"
                  :code="tmparams.code"
                  :config="config"
                  :team="teamEvent"
                  :match="tmparams.match_play"
                  :showGender="showGender"
                  :Stableford="Stableford"
                  :represents_ind="represents_ind"
                  :represents_heading="represents_heading"
                  :represents_flag_type="represents_flag_type"
                  :showFav="tmparams.reg_allow_reg"
                  :is_qualifier="is_qualifier"
                  :show_info_web="show_info_web"
                  :hide_score_card="hide_score_card"
                />
              </b-tab>
              <b-tab title="Hole by Hole" class="hideOnMob" content-class="hbh" :class="{'adt' : develop == 'adt'}">
                <HBH
                  :tabs="tabs"
                  :title="reportMenu"
                  :data="data"
                  :scores="data.scores.scores_entry"
                  :previousData="previousData"
                  :develop="develop"
                  :config="config"
                  :showGender="showGender"
                  :Stableford="Stableford"
                  :represents_ind="represents_ind"
                  :represents_heading="represents_heading"
                  :represents_flag_type="represents_flag_type"
                  :is_qualifier="is_qualifier"
                  :show_info_web="show_info_web"
                  :hide_score_card="hide_score_card"
                />
              </b-tab>
              <!-- <b-tab title="Hole by Hole">
               <HBHTEST
                  :tabs="tabs"
                  :data="data"
                  :scores="data.scores.scores_entry"
                  :previousData="previousData"
                  :develop="develop"
                  :config='config'
                />
              </b-tab> -->
            </b-tabs>
          </div>
          <div v-else-if="this.report_url.indexOf('tmlsmon') !== -1">
            <b-tabs>
              <b-tab :title="title" active>
                <ReportTable
                  :data="data"
                  :scores="data.scores.scores_entry"
                  :previousData="previousData"
                  :home="home"
                  :title="reportMenu"
                  :develop="develop"
                  :season="tmparams.tm_params.season_code"
                  :code="tmparams.code"
                  :config="config"
                  team="N"
                  :match="tmparams.match_play"
                  :showGender="showGender"
                  :Stableford="Stableford"
                  :represents_ind="represents_ind"
                  :represents_heading="represents_heading"
                  :represents_flag_type="represents_flag_type"
                  :showFav="tmparams.reg_allow_reg"
                  :is_qualifier="is_qualifier"
                  :show_info_web="show_info_web"
                  :hide_score_card="hide_score_card"
                />
              </b-tab>
              <b-tab title="Team" v-if="teamEvent === 'Y'">
                <ReportTable
                  :data="data"
                  :scores="data.scores.scores_entry"
                  :previousData="previousData"
                  :home="home"
                  :title="reportMenu"
                  :develop="develop"
                  :season="tmparams.tm_params.season_code"
                  :code="tmparams.code"
                  :config="config"
                  :team="teamEvent"
                  :match="tmparams.match_play"
                  :showGender="showGender"
                  :Stableford="Stableford"
                  :represents_ind="represents_ind"
                  :represents_heading="represents_heading"
                  :represents_flag_type="represents_flag_type"
                  :showFav="tmparams.reg_allow_reg"
                  :is_qualifier="is_qualifier"
                  :show_info_web="show_info_web"
                  :hide_score_card="hide_score_card"
                />
              </b-tab>
              <b-tab title="Hole by Hole" class="hideOnMob" :class="{'adt' : develop == 'adt'}">
                <HBH
                  :tabs="tabs"
                  :data="data"
                  :title="reportMenu"
                  :scores="data.scores.scores_entry"
                  :previousData="previousData"
                  :develop="develop"
                  :config="config"
                  :showGender="showGender"
                  :Stableford="Stableford"
                  :represents_ind="represents_ind"
                  :represents_heading="represents_heading"
                  :represents_flag_type="represents_flag_type"
                  :is_qualifier="is_qualifier"
                  :show_info_web="show_info_web"
                  :hide_score_card="hide_score_card"
                />
              </b-tab>
              <!-- <b-tab title="Hole by Hole">
                <HBHTEST
                  :tabs="tabs"
                  :data="data"
                  :scores="data.scores.scores_entry"
                  :previousData="previousData"
                  :develop="develop"
                  :config='config'
                />
              </b-tab> -->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- <div :class="{colorMess : livemessageText.includes('suspended')}" class="livemessage" v-if="livemessage === 'Y' && livemessageText.length">
      {{livemessageText}}
    </div> -->
    <div
      v-show="livemessage == 'Y' || playSuspended == 'Y'"
      class="livemessage"
      :class="{ colorMess: playSuspended == 'Y' }"
    >
      <div>
        <h3 v-show="playSuspended == 'Y'" class="livemestext">
          Play Suspended
        </h3>
        <h3
          v-show="livemessage == 'Y' && livemessageText.length > 0"
          class="livemestext"
        >
          {{ livemessageText }}
        </h3>
      </div>
    </div>
    <!-- <div class="livemessage" v-if="livemessageText == 'Y' || playSuspended == 'Y'" :class="{colorMess : data.play_suspended == 'Y'}">
        <p class="livemestext">{{ livemessageText }}</p>
      </div> -->
  </div>
</template>

<script>
import axios from "axios";
import {
  HBH
} from "@ocs-software/vue-ocs";
import ReportTable from "@/components/reportTable.vue";

export default {
  name: "Reports",
  props: ["develop", "config", "showGender", "Stableford"],
  components: {
    ReportTable,
    HBH
  },
  data() {
    return {
      data: [],
      previousData: [],
      tmparams: [],
      // showGender: {},
      drop: "drop",
      currentReport: this.$route.query.url,
      home: false,
      tabs: true,
      title: this.$route.query.title,
      reportMenu: this.$route.query.title,
      report_url: this.$route.query.url,
      loading: true,
    };
  },
  methods: {
    changeReport: function() {
      console.log("this.reportTitle")
      console.log(this.reportMenu)
      // console.log("this.title")
      // console.log(this.title)

      for (let i = 0; i < this.filterExemptions.length; i++) {
          // console.log(this.filterExemptions[i].report_title);
          if (this.reportMenu == this.filterExemptions[i].report_title) {
            this.report_url = this.filterExemptions[i].report_url
            console.log("this.report_url")
            console.log(this.report_url)
            console.log('true')
          } 
          // this.report_url = this.filterExemptions[i].report_url
          // console.log("this.report_title")
          // console.log(this.report_title)
      }  
      // var report = event.target.getAttribute("title");
      // console.log(this.report)
      // var title = event.target.getAttribute("value");
      // console.log(title)
      // this.title = title;
      return (
        // (this.currentReport = this.report_url),
        axios
          .get(this.reportTitle)
          .then(response => (this.data = response.data))
      );
    },
    updateStuff: function() {
      axios
        .get(this.reportTitle)
        .then(response => {
          this.data = response.data;
          if (this.report_url.includes("qs.ocs-asia.com")) {
            return axios.get(
              this.config.VUE_APP_TIC_BASE +
                "qsc" +
                "/" +
                this.$route.query.id +
                "/" +
                this.$route.query.id +
                "-" +
                this.$route.query.code +
                "-tmticx.json?randomadd=" +
                new Date().getTime()
            );
          } else {
            return axios.get(
              this.config.VUE_APP_TIC_BASE +
                this.develop +
                "/" +
                this.$route.query.id +
                "/" +
                this.$route.query.id +
                "-" +
                this.$route.query.code +
                "-tmticx.json?randomadd=" +
                new Date().getTime()
            );
          }
        })
        .then(response => {
          this.tmparams = response.data;
        });
    }
  },
  computed: {
    filterExemptions: function() {
      return this.tmparams.reports.reports_entry.filter(
        reports_entry =>
          !reports_entry.report_title.indexOf("Tournament Entries") ||
          !reports_entry.report_title.indexOf("Round 1 Draw") ||
          !reports_entry.report_title.indexOf("Round 2 Draw") ||
          !reports_entry.report_title.indexOf("Round 3 Draw") ||
          !reports_entry.report_title.indexOf("Round 4 Draw") ||
          !reports_entry.report_title.indexOf("Round 5 Draw") ||
          !reports_entry.report_title.indexOf("Round 6 Draw") ||
          !reports_entry.report_title.indexOf("Round 1 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 3 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 4 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 5 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 6 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 3 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 4 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 5 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 6 Scoreboard") ||
          !reports_entry.report_title.indexOf("Final Result")
      );
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    reportTitle: function(url) {
      if (this.report_url.includes("qs.ocs-asia.com")) {
        if (this.report_url.indexOf("tmentry") !== -1) {
          url =
            process.env.VUE_APP_TIC_BASE +
            "qsc" +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-entry-entries.json";
        } else {
          if (this.report_url.indexOf("tmscore") !== -1) {
            var subRep3 = this.report_url.lastIndexOf("params=") + 7;
            var indNext3 = this.report_url.indexOf("~", subRep3);
            var repParams1 = this.report_url.substr(
              subRep3,
              indNext3 - subRep3
            );

            if (repParams1 && repParams1.indexOf(".cgi") < 0) {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                "qsc" +
                "/" +
                this.$route.query.id +
                "/" +
                this.$route.query.id +
                "-" +
                this.$route.query.code +
                "-scores-" +
                repParams1 +
                ".json" +
                "?randomadd=" +
                new Date().getTime();
            } else {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                "qsc" +
                "/" +
                this.$route.query.id +
                "/" +
                this.$route.query.id +
                "-" +
                this.$route.query.code +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.report_url.indexOf("tmlsmon") !== -1) {
            {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                "qsc" +
                "/" +
                this.$route.query.id +
                "/" +
                this.$route.query.id +
                "-" +
                this.$route.query.code +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.report_url.indexOf("tmdraw") !== -1) {
            var subRep5 = this.report_url.lastIndexOf("round=") + 6;
            var indNext5 = this.report_url.indexOf("~", subRep5);
            var drawRnd5 = this.report_url.substr(
              subRep5,
              indNext5 - subRep5
            );
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              "qsc" +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-draw-R" +
              drawRnd5 +
              "-draw.json";
          } else if (this.report_url.indexOf("tmresult") !== -1) {
            var subRep4 = this.report_url.lastIndexOf("result=") + 7;
            var indNext4 = this.report_url.indexOf("~", subRep4);
            var resNum4 = this.report_url.substr(
              subRep4,
              indNext4 - subRep4
            );
            // console.log("resNum === ");
            // console.log(resNum1);
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              "qsc" +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-result-result-" +
              resNum4 +
              ".json";
          } else {
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              "qsc" +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-result-result-PF.json";
          }
        }
        return url;
      } else {
        if (this.report_url.indexOf("tmentry") !== -1) {
          url =
            process.env.VUE_APP_TIC_BASE +
            this.develop +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-entry-entries.json";
        } else {
          if (this.report_url.indexOf("tmscore") !== -1) {
            var subRep2 = this.report_url.lastIndexOf("params=") + 7;
            var indNext2 = this.report_url.indexOf("~", subRep2);
            var repParams = this.report_url.substr(
              subRep2,
              indNext2 - subRep2
            );

            if (repParams && repParams.indexOf(".cgi") < 0) {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                this.develop +
                "/" +
                this.$route.query.id +
                "/" +
                this.$route.query.id +
                "-" +
                this.$route.query.code +
                "-scores-" +
                repParams +
                ".json" +
                "?randomadd=" +
                new Date().getTime();
            } else {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                this.develop +
                "/" +
                this.$route.query.id +
                "/" +
                this.$route.query.id +
                "-" +
                this.$route.query.code +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.report_url.indexOf("tmlsmon") !== -1) {
            {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                this.develop +
                "/" +
                this.$route.query.id +
                "/" +
                this.$route.query.id +
                "-" +
                this.$route.query.code +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.report_url.indexOf("tmdraw") !== -1) {
            var subRep = this.report_url.lastIndexOf("round=") + 6;
            var indNext = this.report_url.indexOf("~", subRep);
            var drawRnd = this.report_url.substr(subRep, indNext - subRep);
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              this.develop +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-draw-R" +
              drawRnd +
              "-draw.json";
          } else if (this.report_url.indexOf("tmresult") !== -1) {
            var subRep1 = this.report_url.lastIndexOf("result=") + 7;
            var indNext1 = this.report_url.indexOf("~", subRep1);
            var resNum1 = this.report_url.substr(
              subRep1,
              indNext1 - subRep1
            );
            // console.log("resNum === ");
            // console.log(resNum1);
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              this.develop +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-result-result-" +
              resNum1 +
              ".json";
          } else {
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              this.develop +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-result-result-PF.json";
          }
        }
        return url;
      }
    }
  },
  created() {
    setInterval(this.updateStuff, 30000);
  },
  mounted() {
    setTimeout(() => {
      axios
        .get(this.reportTitle)
        .then(response => {
          this.data = response.data;
          this.represents_ind = response.data.represents_ind;
          this.represents_heading = response.data.represents_heading;
          this.represents_flag_type = response.data.represents_flag_type;
          this.loading = true
          if (this.currentReport.includes("qs.ocs-asia.com")) {
            return axios.get(
              process.env.VUE_APP_TIC_BASE +
                "qsc" +
                "/" +
                this.$route.query.id +
                "/" +
                this.$route.query.id +
                "-" +
                this.$route.query.code +
                "-tmticx.json?randomadd=" +
                new Date().getTime()
            );
          } else {
            return axios.get(
              process.env.VUE_APP_TIC_BASE +
                this.develop +
                "/" +
                this.$route.query.id +
                "/" +
                this.$route.query.id +
                "-" +
                this.$route.query.code +
                "-tmticx.json?randomadd=" +
                new Date().getTime()
            );
          }
        })
        .then(response => {
          this.tmparams = response.data;
          this.showGender = response.data.show_gender;
          this.Stableford = response.data.stableford;
          this.show_info_web = response.data.tm_params.show_info_web;
          this.hide_score_card = response.data.hide_score_card;
          this.is_qualifier = response.data.is_qualifier;
          this.livemessage = response.data.live_message;
          this.livemessageText = response.data.live_scoring_mess;
          this.playSuspended = response.data.play_suspended;
          this.DEFIMAGESAMS3 = response.data.tm_params.DEFIMAGESAMS3;
          this.loading = true
        });
    }, 1000);
  }
};
</script>

<style scoped>
::v-deep img.informationImg.not-collapsed {
    width: 15px;
}
::v-deep img.informationImg.collapsed {
    width: 15px;
}
[v-cloak] {
  display: none;
}
::v-deep .tabs.adt > div > ul > li > .nav-link.active {
  background-color: #76c3bd !important;
  border-color: #76c3bd !important;
  background-color: #1c857c !important;
  border-color: #1c857c !important;
}
::v-deep .tabs.adt > div > ul > li > .nav-link{
  background-color: #76c3bd !important;
  border-color: #76c3bd !important;
}
::v-deep .tabs.adt > div > ul > li > .nav-link:hover{
  background-color: #1c857c !important;
  border-color: #1c857c !important;
}
::v-deep .adtColour {
  color: #76c3bd!important;
}
::v-deep .custom-select {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0px solid #c7c7cc;
  height: 43px;
  font-size: 16px;
  width: 365px;
  color: #7E7E7E !important;
  background: #F5F5F5 url(https://assets.asiantour.com/asian-tour/2024/03/DarkGrey.png) right 0.75rem center / 12px 8px no-repeat;
}
.tournInfo {
  margin-top: 50px;
}
::v-deep h2.tournTitle {
  color: #0A3F7F;
  font-size: 20px;
  font-weight: 500;
}
::v-deep p.tournDates {
  color: #7E7E7E;
  font-weight: 200;
  font-size: 16px;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5)!important;
}
::v-deep .btn-secondary:focus, .btn-secondary.focus {
  color: #7E7E7E;
  background-color: rgb(255, 255, 255);
  border-color: #ffffff;
  box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5)!important;
}
::v-deep .dropdown-toggle::after {
  content: none!important;
}
::v-deep .btn-secondary:focus, .btn-secondary.focus {
  color: #7E7E7E;
  background-color: #F5F5F5;
  border-color: #F5F5F5;
  box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5)!important;
}
::v-deep .btn-secondary {
  color: #7E7E7E;
  background-color: #F5F5F5;
  border-color: #ebebeb;
  border-radius: 0px;
  font-size: 16px;
  width: 365px;
  height: 43px;
  border: 0px solid;
  text-align: left;
  background: #F5F5F5 url(https://assets.asiantour.com/asian-tour/2024/03/DarkGrey.png) right 0.75rem center / 12px 8px no-repeat;
}
.noPadLogo_left {
  padding-left: 0;
}
::v-deep .dropdownReport > ul {
  width: 100% !important;
}
::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #7E7E7E !important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 16px !important;
  text-align: left;
}
.iframepadnoright {
  padding-right: 0px;
}
.ReportBkwhite {
  /* background-color: #fff;
  height: 100px; */
}
.colorMess {
  background: red !important;
  color: white;
  font-weight: 500;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.livemessage {
  background: #133f7b;
  color: white;
  font-weight: 500;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.reports {
  padding-top: 4px;
}
span.reportsPad {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  display: contents;
}
::v-deep .leaderboardTabs > div > ul {
  display: none;
}
.reportTitle {
  margin-top: 1.5em;
}
::v-deep .nav-tabs .nav-link {
  border-radius: 0px;
  border: 0px solid transparent;
}
::v-deep .nav-tabs {
  border-bottom: 0;
  /* background-color: #d4d4d4; */
}
::v-deep .nav-tabs > li > a {
  color: #fff !important;
  height: 43px!important;
  line-height: 2;
  background: #A8B7C9;
}
::v-deep .nav-tabs > li > a:hover {
  color: #fff !important;
  height: 43px!important;
  line-height: 2;
  background: #A8B7C9;
  border: #A8B7C9;
}
.cor-grn {
  color: #3c9933 !important;
  float: right;
  margin-top: 13px;
  font-size: 60px;
}
::v-deep .tab-pane > div > table > thead > tr > th:first-child {
  border-top-left-radius: 0;
}
::v-deep .tab-pane > div > table > thead > tr > th:last-child {
  border-top-right-radius: 0;
}
.cor-org {
  color: #e07020 !important;
  float: right;
  margin-top: 13px;
  font-size: 60px;
}
.cut-row {
  background: #aa0003 !important;
  color: #fff;
  text-align: center;
}
.iframemovement {
  float: right;
  margin-right: -3px;
}
.bannerlogosReport {
  float: right;
}
::v-deep .dropdownReport > ul {
  width: 300px;
  text-align: center;
}
::v-deep .btn-secondary {
  color: #7E7E7E;
  background-color: #F5F5F5;
  border-color: #ebebeb;
  border-radius: 0px;
  font-size: 16px;
  width: 365px;
  height: 43px;
  border: 0px solid;
  text-align: left;
}
.ReportFilter {
  display: flex;
}
.filterReports {
  color: #16498f;
  font-size: 10pt;
  font-weight: bold;
}
.main {
  padding-top: 2%;
  padding-bottom: 2%;
}
.drop {
  padding-bottom: 2%;
  margin-top: 30px;
}
.developGreen {
  color: #76c3bd !important;
}
::v-deep .developGreen > div > ul > li > .nav-link.active {
  background-color: #76c3bd !important;
  border-color: #76c3bd !important;
}
.sponsor {
  margin-top: -10%;
  width: 20%;
  padding-left: 10%;
}
.multi {
  font-size: 13px !important;
  line-height: 50px;
  color: #0b3f7e;
  font-weight: bold;
  margin-top: 15px;
}
.B {
  color: #333333;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.Y {
  color: #d8db22;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.C {
  color: #1e90ff;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.R {
  color: #bb0000;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.G {
  color: #3c9933;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.O {
  color: #e07020;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}

::v-deep .nav-link.active {
  color: white !important;
  background-color: #042F62 !important;
  width: 100%;
  border-color: #042F62 !important;
  height: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
::v-deep .nav-link:hover {
  color: white !important;
  background-color: #5897E6 !important;
  width: 100%;
  border-color: #5897E6 !important;
  height: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.selectRD {
  /* padding: 20px 1%; */
  padding: 30px;
}
::v-deep .nav-link {
  color: black !important;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.dropSel {
  padding: 0.5% 1%;
  background-color: #fff;
  /* border-radius: 10px; */
  height: 100px;
}
::v-deep li.nav-item {
  width: 25%;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 1px;
}
::v-deep .mt-3 {
  margin-top: 0 !important;
}
.tourLogoS {
    max-width: 250px;
    max-height: 105px;
}
.tournHead {
  text-align: left;
  color: #133f7a;
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 19px !important;
}
.dates {
  font-size: 13px !important;
}
.top {
  background-color: #ebebeb;
}
.mid {
  background-color: #f1f1f1;
}
::v-deep .removeTR {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .ReportBkwhite {
    background-color: transparent;
    height: auto;
  }
  span.reportPad {
    width: 510px !important;
    padding-left: 15px;
    padding-right: 15px;
    display: block !important;
  }
  .bannerlogosReport {
    display: none;
  }
  .iframemovement {
    float: right;
    margin-right: -3px;
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  .noPadLogo_left {
    padding-left: 15px;
  }
  span.reportsPad {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    display: block !important;
  }
  .tourLogoS {
    margin-bottom: 0;
    padding-top: 50px;
    margin-top: 0px;
    max-height: 100% !important;
    text-align: center;
    display: block;
    margin: auto;
  }
  .drop {
    padding-left: 35%;
  }
  .mobC {
    text-align: center;
  }
  ::v-deep .nav-tabs > li.nav-item {
    width: 100% !important;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .mobHide {
    display: none;
  }
  span.reportsPad {
    margin-top: 12px;
  }
  ::v-deep .dropdownReport > ul {
    width: 100%;
  }
  ::v-deep ul.dropdown-menu.show {
    transform: translate3d(0px, 35px, 4px) !important;
    border-radius: 0px;
  }
}
@media only screen and (max-width: 500px) {
  ::v-deep .nav-tabs > li.nav-item + li.nav-item {
    display: none;
  }
  ::v-deep .multi {
    font-size: 13px !important;
    line-height: 50px;
    color: #0b3f7e;
    font-weight: bold;
    margin-top: 35px;
  }
}
@media only screen and (max-width: 480px) {
  .ReportFilter {
    display: block;
    text-align: center;
    position: relative;
    top: 8px;
  }
  .sponsor {
    margin-top: 2%;
    width: 35%;
    padding-left: 4%;
  }
  .drop {
    /* margin-top: -10%; */
    margin-top: 0;
    padding: 0;
  }
  .selectRD {
    padding: 2% 4%;
  }
  .reportDropdown {
    margin: auto;
    display: block;
  }
  .dropdownReport {
    display: contents;
  }
  ::v-deep .nav-link {
    font-size: 12pt;
  }
}
</style>